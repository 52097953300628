import * as React from 'react';
import './App.css';
import spec from './openapi.json';

import {RedocStandalone} from 'redoc';

function App() {

    spec["info"]["x-logo"] = {
        "url": "https://heva-logos.s3.eu-west-2.amazonaws.com/docs-logo.png"
    }

    return (
        <div className="App">
            <RedocStandalone spec={spec} options={{
                nativeScrollbars: true,
                // theme: {colors: {primary: {main: '#dd5522'}}},
            }}/>
        </div>
    );
}

export default App;
